<template>
  <div class="skill container is-fullhd">
    <h1 class="title">{{title}}</h1>
    <CourseCrumb :course="course" :skill="skill" />

    <section class="columns">

      <div class="column is-three-quarters">
        <CardList v-if="skill" :items="skill.lessons" :url="fullUrl" :showProgress="true" />
      </div>

      <Sidebar class="column" v-if="loggedIn" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import API from '@/services/api';
import Sidebar from '@/components/Sidebar.vue';
import CardList from '@/components/CardList.vue';
import CourseCrumb from '@/components/CourseCrumb.vue';

export default {
  name: 'Skill',
  components: {
    Sidebar,
    CardList,
    CourseCrumb,
  },
  data() {
    return {
      url: '',
      loading: true,
      skill: null,
    };
  },
  created() {
    this.id = this.$route.params.skillId;
    this.courseUrl = this.$route.params.courseUrl;
    this.url = this.$route.params.skillUrl;
    this.getSkill();
  },
  computed: {
    ...mapGetters({
      user: 'session/user',
      loggedIn: 'session/loggedIn',
      courseByUrl: 'site/course',
      courseById: 'site/courseById',
    }),
    course() {
      if (this.courseUrl) {
        return this.courseByUrl(this.courseUrl)[0];
      }
      if (this.skill) {
        return this.courseById(this.skill.courseId)[0];
      }
      return null;
    },
    title() {
      if (this.skill) return this.skill.name;
      if (this.url) {
        return this.url[0].toUpperCase() + this.url.substr(1);
      }
      return 'Skill';
    },
    fullUrl() {
      return `/course/${this.course.url}/${this.skill.url}`;
    },
  },
  methods: {
    async getSkill() {
      try {
        let res;
        if (this.id) {
          res = await API.skills.get(this.id);
        } else {
          res = await API.skills.find(this.courseUrl, this.url);
        }
        this.skill = res.data.skill;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
      this.loading = false;
    },
  },
};
</script>
